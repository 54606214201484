<template>
    <v-container fluid>
        <v-data-table
                :headers="headers"
                :items="offers.data"
                :items-per-page="paginationParams.per_page"
                :page="paginationParams.page"
                :server-items-length="offers.total"
                :loading="loading"
                sort-by="id"
                class="border"
                @update:page="changePage"
                @update:items-per-page="changeItemsPerPage"
                :footer-props="footerProps"
                @update:sort-by="sortBy"
                @update:sort-desc="sortDesc"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" fullscreen persistent max-width="500px">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.offer.newItem') }}</v-btn>
                        </template>
                        <offer-form v-if="dialogLoaded" :options="options" :edited-item="editedItem" v-on:saved="onSave" v-on:close="close"></offer-form>
                    </v-dialog>
                </v-toolbar>
                <v-container>
                    <v-row>
                        <v-col style="min-width: 200px;">
                            <v-text-field
                                    v-model="filters.label"
                                    :label="trans('fields.common.label')"
                                    clearable
                            ></v-text-field>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-select
                                    :items="options.offerTypes"
                                    :label="trans('fields.common.offerTypes')"
                                    :multiple="true"
                                    v-model="filters.type_id"
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-select
                                    :items="options.offerScopes"
                                    :label="trans('fields.common.scope')"
                                    :multiple="true"
                                    v-model="filters.scope"
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-select
                                    :items="options.countries"
                                    :label="trans('fields.common.countries')"
                                    :multiple="true"
                                    v-model="filters.country_id"
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-select
                                    :items="options.languages"
                                    :label="trans('fields.common.languages')"
                                    :multiple="true"
                                    v-model="filters.language_id"
                                    clearable
                            ></v-select>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <user-autocomplete
                                    v-model="filters.user_id"
                                    :label="trans('fields.common.user')"
                                    clearable
                            ></user-autocomplete>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                @click="applyFilters"
                        >{{ trans('buttons.common.apply') }}</v-btn>
                        <v-btn
                                class="ma-2"
                                color="orange"
                                outlined
                                @click="clearFilters"
                        >{{ trans('buttons.common.clear') }}</v-btn>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small color="red" @click="deleteDialogShow(item.id)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.language="{ item }">
                {{ options.languagesKeyed ? options.languagesKeyed[item.language_id] : '' }}
            </template>
            <template v-slot:item.offerType="{ item }">
                {{ options.offerTypesKeyed ? options.offerTypesKeyed[item.type_id] : '' }}
            </template>
            <template v-slot:item.countries="{ item }">
                {{ options.countriesKeyed ? item.countriesArr.map((id) => options.countriesKeyed[id]).join(', ') : '' }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | mysqlDate }}
            </template>
            <template v-slot:item.scope="{ item }">
                {{ options.offerScopesKeyed ? options.offerScopesKeyed[item.scope] : '' }}
            </template>
            <template v-slot:item.user="{ item }">
                <user-link :user="item.user"></user-link>
            </template>

        </v-data-table>


        <delete-dialog
                :dialogTrigger="deleteDialog"
                :item-id="deleteItemId"
                :delete-route="deleteRoute"
                @closed="deleteDialogClosed"
                @deleted="deleteDialogDeleted"
        >
            <template v-slot:title>
                {{ trans('pages.offer.deleteDialogTitle') }}
            </template>
            <template v-slot:text>
                {{ trans('pages.offer.deleteDialogText', {id: deleteItemId}) }}
            </template>
        </delete-dialog>

    </v-container>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog"
    import tableFilters from "@/mixins/tableFilters";
    import UserLink from "@/components/admin/user/UserLink";
    import UserAutocomplete from "@/components/admin/user/UserAutocomplete";

    export default {
        name: "OfferTable",
        components: {
            UserAutocomplete,
            UserLink,
            OfferForm: () => import('@/components/admin/offer/OfferForm'),
            DeleteDialog,
        },
        mixins: [tableWithPagination, deleteDialog, tableFilters],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                offers: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.offerType"), value: "offerType", sortable: false },
                    { text: this.trans("fields.common.language"), value: "language", sortable: false },
                    { text: this.trans("fields.common.countries"), value: "countries", sortable: false },
                    { text: this.trans("fields.common.status"), value: "statusLabel", sortable: false },
                    { text: this.trans("fields.common.scope"), value: "scope", sortable: false },
                    { text: this.trans("fields.common.user"), value: "user", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.offers.index'),
                deleteRoute: 'admin.offers.delete',

                editedItem: {},
                defaultItem: {
                    id: "",
                    label: "",
                    language_id: "",
                    type_id: null,
                    status: 0,
                    scope: 0,
                    user_id: null,
                    fields: {},
                    countries: [],
                },
                filters: {
                    label: '',
                    scope: null,
                    country_id: null,
                    language_id: null,
                    type_id: null,
                    user_id: null,
                },
            }
        },
        created() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.initialize();
            this.loadOptions({languages: true, offerScopes: true, offerTypes: true, countries: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = JSON.parse(JSON.stringify(item));
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.offers;
                },
                set(v){
                    this.offers = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
